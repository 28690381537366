import './bootstrap';
import './vendor/fslightbox';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();


// Initialize the service worker for PWA
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceworker.js', {
        scope: '.'
    }).then(function (registration) {
        //console.log('PWA: ServiceWorker registration successful with scope: ', registration.scope);
    }, function (err) {
        console.log('PWA: ServiceWorker registration failed: ', err);
    });
}
